import { takeLatest } from "redux-saga/effects";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import { createRequestSaga } from "@sellernote/shipda-kr/src/services/legacyRequest";

// Action Type
const prefix = "auth/";
export const actionType = {
  INIT: `${prefix}INIT`,

  FIND_ID: `${prefix}FIND_ID`,
  FIND_ID_SUCCESS: `${prefix}FIND_ID_SUCCESS`,
  FIND_ID_FAILURE: `${prefix}FIND_ID_FAILURE`,
  INIT_FIND_ID: `${prefix}INIT_FIND_ID`,

  FIND_PASSWORD: `${prefix}FIND_PASSWORD`,
  FIND_PASSWORD_SUCCESS: `${prefix}FIND_PASSWORD_SUCCESS`,
  FIND_PASSWORD_FAILURE: `${prefix}FIND_PASSWORD_FAILURE`,
  INIT_FIND_PASSWORD: `${prefix}INIT_FIND_PASSWORD`,

  CHECK_REGISTERD_EMAIL: `${prefix}CHECK_REGISTERD_EMAIL`,
  CHECK_REGISTERD_EMAIL_SUCCESS: `${prefix}CHECK_REGISTERD_EMAIL_SUCCESS`,
  CHECK_REGISTERD_EMAIL_FAILURE: `${prefix}CHECK_REGISTERD_EMAIL_FAILURE`,
  INIT_CHECK_REGISTERD_EMAIL: `${prefix}INIT_CHECK_REGISTERD_EMAIL`,
};

// Actions
export const actions = {
  init: () => {
    return { type: actionType.INIT };
  },

  initFindPassword: () => {
    return { type: actionType.INIT_FIND_PASSWORD };
  },
  findPassword: (email: string) => {
    const payload = {
      email,
    };
    return { type: actionType.FIND_PASSWORD, payload };
  },

  initCheckRegisteredEmail: () => {
    return { type: actionType.INIT_CHECK_REGISTERD_EMAIL };
  },
  /**
   * 이메일로 가입된 회원 유무를 확인하는 것은 checkDuplicateEmail과 동일하지만,
   * 검사와 동시에 마케팅용도의 이메일 수집을 한다는 점에서 다르다 (서버에서 수행)
   * @param email
   */
  checkRegisteredEmail: (email: string) => {
    const payload = {
      email,
    };
    return { type: actionType.CHECK_REGISTERD_EMAIL, payload };
  },
};

// Sagas
const sagas = {
  findPasswordSaga: createRequestSaga(
    actionType.FIND_PASSWORD,
    (payload: any) => {
      return sendRequest({
        method: "post",
        path: "/auth/find/password",
        data: payload,
        apiType: "SHIPDA_API",
      });
    }
  ),

  checkRegisteredEmailSaga: createRequestSaga(
    actionType.CHECK_REGISTERD_EMAIL,
    ({ email }: { email: string }) => {
      return sendRequest({
        method: "get",
        path: `/users/${email}/registration`,
        apiType: "SHIPDA_API",
      });
    }
  ),
};
export function* authSaga() {
  yield takeLatest(actionType.FIND_PASSWORD, sagas.findPasswordSaga);
  yield takeLatest(
    actionType.CHECK_REGISTERD_EMAIL,
    sagas.checkRegisteredEmailSaga
  );
}
