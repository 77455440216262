import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { GET_TRACKING_INFO_OF_PARCEL_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/fulfillment";
import { GET_RETURNING_INPROGRESS_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/boful-api/returning";
import {
  GET_GROUP_SKU_DETAIL_REQ,
  GET_GROUP_SKU_DETAIL_RES,
  GET_SINGLE_SKU_DETAIL_REQ,
  GET_SINGLE_SKU_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/sku";
import { createReducerWithSagaBundleForShipDa } from "@sellernote/_shared/src/services/request";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { FulfillmentParcelCompany } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ShippingItemDetail } from "@sellernote/_shared/src/types/fulfillment/shipping";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { loadingActions } from "../loading";
export interface FulfillmentState {
  GET_SKU_ITEM_DETAIL: Partial<ApiResponseState<GET_SINGLE_SKU_DETAIL_RES>>;

  GET_GROUP_SKU_ITEM_DETAIL: Partial<
    ApiResponseState<GET_GROUP_SKU_DETAIL_RES>
  >;

  GET_SHIPPING_TRACKING_INFO_OF_PARCEL?: Partial<
    ApiResponseState<GET_TRACKING_INFO_OF_PARCEL_RES>
  >;
  GET_RETURNING_TRACKING_INFO_OF_PARCEL?: Partial<
    ApiResponseState<GET_TRACKING_INFO_OF_PARCEL_RES>
  >;

  GET_SHIPPING_ITEM_DETAIL?: Partial<ApiResponseState<ShippingItemDetail>>;

  CANCEL_SHIPPING_ITEM?: Partial<ApiResponseState<any>>;

  APPLY_FULFILLMENT?: Partial<ApiResponseState<any>>;

  GET_RETURNING_DETAIL?: Partial<
    ApiResponseState<GET_RETURNING_INPROGRESS_DETAIL_RES>
  >;

  REQUEST_RETURNING?: Partial<ApiResponseState<any>>;
}

const initialState: FulfillmentState = {
  GET_SKU_ITEM_DETAIL: apiResponseInitialState,

  GET_GROUP_SKU_ITEM_DETAIL: apiResponseInitialState,

  GET_SHIPPING_ITEM_DETAIL: apiResponseInitialState,

  CANCEL_SHIPPING_ITEM: apiResponseInitialState,

  GET_SHIPPING_TRACKING_INFO_OF_PARCEL: apiResponseInitialState,
  GET_RETURNING_TRACKING_INFO_OF_PARCEL: apiResponseInitialState,

  APPLY_FULFILLMENT: apiResponseInitialState,

  GET_RETURNING_DETAIL: apiResponseInitialState,

  REQUEST_RETURNING: apiResponseInitialState,
};

const SLICE_NAME = "fulfillment";

const GET_SKU_ITEM_DETAIL = createReducerWithSagaBundleForShipDa<
  "GET_SKU_ITEM_DETAIL",
  GET_SINGLE_SKU_DETAIL_REQ,
  GET_SINGLE_SKU_DETAIL_RES,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_SKU_ITEM_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/sku/${payload.skuId}`,
      apiType: "BOFUL_API",
    };
  },
  loadingActions,
});

const GET_GROUP_SKU_ITEM_DETAIL = createReducerWithSagaBundleForShipDa<
  "GET_GROUP_SKU_ITEM_DETAIL",
  GET_GROUP_SKU_DETAIL_REQ,
  GET_GROUP_SKU_DETAIL_RES,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_GROUP_SKU_ITEM_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/group/${payload.groupSkuId}`,
      apiType: "BOFUL_API",
    };
  },
  loadingActions,
});

const GET_SHIPPING_ITEM_DETAIL = createReducerWithSagaBundleForShipDa<
  "GET_SHIPPING_ITEM_DETAIL",
  { shippingId: number },
  ShippingItemDetail,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_SHIPPING_ITEM_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/shipper/${payload.shippingId}`,
      apiType: "BOFUL_API",
    };
  },
  loadingActions,
});

const CANCEL_SHIPPING_ITEM = createReducerWithSagaBundleForShipDa<
  "CANCEL_SHIPPING_ITEM",
  any,
  any,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "CANCEL_SHIPPING_ITEM",
  getRequestOptions: (payload) => {
    return {
      method: "post",
      path: `/canceling/user`,
      apiType: "BOFUL_API",
      data: payload,
    };
  },
  loadingActions,
});

const GET_SHIPPING_TRACKING_INFO_OF_PARCEL =
  createReducerWithSagaBundleForShipDa<
    "GET_SHIPPING_TRACKING_INFO_OF_PARCEL",
    { invoiceNo: string; parcelCompany: FulfillmentParcelCompany },
    GET_TRACKING_INFO_OF_PARCEL_RES,
    FulfillmentState
  >({
    sliceName: SLICE_NAME,
    actionTypeKey: "GET_SHIPPING_TRACKING_INFO_OF_PARCEL",
    getRequestOptions: ({ invoiceNo, parcelCompany }) => {
      return {
        method: "get",
        path: `/parcel/tracking`,
        apiType: "BOFUL_API",
        params: { invoiceNo, parcelCompany },
      };
    },
    loadingActions,
  });

const GET_RETURNING_TRACKING_INFO_OF_PARCEL =
  createReducerWithSagaBundleForShipDa<
    "GET_RETURNING_TRACKING_INFO_OF_PARCEL",
    { invoiceNo: string; parcelCompany: FulfillmentParcelCompany },
    GET_TRACKING_INFO_OF_PARCEL_RES,
    FulfillmentState
  >({
    sliceName: SLICE_NAME,
    actionTypeKey: "GET_RETURNING_TRACKING_INFO_OF_PARCEL",
    getRequestOptions: ({ invoiceNo, parcelCompany }) => {
      return {
        method: "get",
        path: `/returning/user/tracking/parcel`,
        apiType: "BOFUL_API",
        params: { invoiceNo, parcelCompany },
      };
    },
    loadingActions,
  });

const APPLY_FULFILLMENT = createReducerWithSagaBundleForShipDa<
  "APPLY_FULFILLMENT",
  Record<string, unknown>,
  any,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "APPLY_FULFILLMENT",
  getRequestOptions: () => {
    return {
      method: "patch",
      path: `/users/apply/fullfillment`,
      apiType: "SHIPDA_API",
    };
  },
  loadingActions,
});

const GET_RETURNING_DETAIL = createReducerWithSagaBundleForShipDa<
  "GET_RETURNING_DETAIL",
  { id: number },
  GET_RETURNING_INPROGRESS_DETAIL_RES,
  FulfillmentState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_RETURNING_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/returning/list/${payload.id}`,
      apiType: "BOFUL_API",
    };
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...GET_SKU_ITEM_DETAIL.reducerBundle,

    ...GET_GROUP_SKU_ITEM_DETAIL.reducerBundle,

    ...GET_SHIPPING_ITEM_DETAIL.reducerBundle,

    ...CANCEL_SHIPPING_ITEM.reducerBundle,

    ...GET_SHIPPING_TRACKING_INFO_OF_PARCEL.reducerBundle,
    ...GET_RETURNING_TRACKING_INFO_OF_PARCEL.reducerBundle,

    ...APPLY_FULFILLMENT.reducerBundle,

    ...GET_RETURNING_DETAIL.reducerBundle,
  },
});

function* saga() {
  yield takeLatest(
    slice.actions.GET_SKU_ITEM_DETAIL.type,
    GET_SKU_ITEM_DETAIL.saga
  );

  yield takeLatest(
    slice.actions.GET_GROUP_SKU_ITEM_DETAIL.type,
    GET_GROUP_SKU_ITEM_DETAIL.saga
  );

  yield takeLatest(
    slice.actions.GET_SHIPPING_ITEM_DETAIL.type,
    GET_SHIPPING_ITEM_DETAIL.saga
  );

  yield takeLatest(
    slice.actions.CANCEL_SHIPPING_ITEM.type,
    CANCEL_SHIPPING_ITEM.saga
  );

  yield takeLatest(
    slice.actions.GET_SHIPPING_TRACKING_INFO_OF_PARCEL.type,
    GET_SHIPPING_TRACKING_INFO_OF_PARCEL.saga
  );
  yield takeLatest(
    slice.actions.GET_RETURNING_TRACKING_INFO_OF_PARCEL.type,
    GET_RETURNING_TRACKING_INFO_OF_PARCEL.saga
  );

  yield takeLatest(
    slice.actions.APPLY_FULFILLMENT.type,
    APPLY_FULFILLMENT.saga
  );

  yield takeLatest(
    slice.actions.GET_RETURNING_DETAIL.type,
    GET_RETURNING_DETAIL.saga
  );

  yield takeLatest(
    slice.actions.GET_RETURNING_DETAIL.type,
    GET_RETURNING_DETAIL.saga
  );
}

export default slice;

export { saga };
